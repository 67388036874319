<template>
  <div class="about-us">
    <div class="introduction">
      <div class="content">
        <h1>简介</h1>
        <p>
          {{ about_us_introduction }}
        </p>
        <p></p>
      </div>
    </div>

    <div class="contract">
      <div class="content">
        <h1>联系我们</h1>
        <div class="emil_info">
          <span>邮箱 : </span>
          <span>{{ emil_addrss }}</span>
        </div>
      </div>
    </div>

    <h1></h1>
  </div>
</template>

<script type="text/babel">
import CONSTANT from "../config/constant.js";
export default {
  data() {
    return {};
  },

  computed: {
    emil_addrss() {
      return CONSTANT.EMIL_ADDRESS;
    },

    about_us_introduction() {
      return CONSTANT.ANOUT_US_INTROUCTION;
    },
  },
};
</script>

<style scoped>
.introduction {
  width: 100%;
  height: 100%;
}

.introduction > .content {
  padding: 20px 15%;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  flex-wrap: wrap;
}

.contract {
  padding: 20px 15%;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  flex-wrap: wrap;
}
.emil_info {
  /* padding: ; */
}
p,
span {
  line-height: 2;
}
</style>
